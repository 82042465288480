<template>
    <main>
        <section style="padding: 3%" v-for="state in postulaciones" v-bind:key="state">
            <!-- Vista Documentos -->
            <div>
                <div style="display: flex; flex-direction: row">
                    <div style="flex: 3">
                        <h5>Tus Documentos</h5>
                        <p>
                            En este apartado podrás visualizar los documentos que has subido a
                            la plataforma
                        </p>
                        <p>
                            Para subir / actualizar tu <b>CV</b> o <b>Comprobante de Estudios</b> da click -- <router-link to="/informationStudent">aquí</router-link> -- busca el apartado <b>Archivos y Enlaces</b>  
                          
                        </p>
                       
                    </div>
                </div>
                <!--Upload inicial Becario -->
                <div style="display: flex;flex-wrap: wrap; justify-content: center; padding: 2rem;" 
                    v-show="state.informationVacante.type === 'Becario'">
                    <!-- 1 CV -->
                    <div style="display: flex;justify-content: center;align-items: center;padding: 2%;" 
                        v-show="state.informationUser.filecv">
                        <box-icon name="check" color="#39811d"></box-icon>
                        <a style="text-decoration: none;display: flex;justify-content: center;align-items: center;" 
                            title="CV" target="_blank" :href="state.informationUser.filecv?.link">
                            <box-icon name="file" color="#106cf6"></box-icon>
                            <small>CV</small>
                        </a>
                    </div>
                    <!-- 2 estudios -->
                    <div style="
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 2%;
            " v-show="state.informationUser.filecomprobantes">
                        <box-icon name="check" color="#39811d"></box-icon>
                        <a style="
                text-decoration: none;
                display: flex;
                justify-content: center;
                align-items: center;
              " title="Documento Comprobante" target="_blank" :href="state.informationUser.filecomprobantes?.link">
                            <box-icon name="file" color="#106cf6"></box-icon>
                            <small>Comprobante de Estudios</small>
                        </a>
                    </div>
                    <!-- 3 Domicilio -->
                    <div style="
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 2%;
            " v-show="state.document_comprobante">
                        <box-icon name="check" color="#39811d"></box-icon>
                        <a style="
                text-decoration: none;
                display: flex;
                justify-content: center;
                align-items: center;
              " title="Documento Comprobante" target="_blank" :href="state.document_comprobante?.link">
                            <box-icon name="file" color="#106cf6"></box-icon>
                            <small>Documento Comprobante de Domicilio</small>
                        </a>
                    </div>
                    <!-- 4 Documento INE -->
                    <div style="
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 2%;
            " v-show="state.document_ine">
                        <box-icon name="check" color="#39811d"></box-icon>
                        <a style="
                text-decoration: none;
                display: flex;
                justify-content: center;
                align-items: center;
              " title="Documento INE" target="_blank" :href="state.document_ine?.link">
                            <box-icon name="file" color="#106cf6"></box-icon>
                            <small>Documento INE</small>
                        </a>
                    </div>
                    <!-- 5 Documento Acta -->
                    <div style="
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 2%;
            " v-show="state.document_acta">
                        <box-icon name="check" color="#39811d"></box-icon>
                        <a style="
                text-decoration: none;
                display: flex;
                justify-content: center;
                align-items: center;
              " title="Documento Acta" target="_blank" :href="state.document_acta?.link">
                            <box-icon name="file" color="#106cf6"></box-icon>
                            <small>Documento Acta de Nacimiento</small>
                        </a>
                    </div>
                    <!-- 6 Documento CURP -->
                    <div style="
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 2%;
            " v-show="state.document_curp">
                        <box-icon name="check" color="#39811d"></box-icon>
                        <a style="
                text-decoration: none;
                display: flex;
                justify-content: center;
                align-items: center;
              " title="Documento CURP" target="_blank" :href="state.document_curp?.link">
                            <box-icon name="file" color="#106cf6"></box-icon>
                            <small>Documento CURP</small>
                        </a>
                    </div>
                    <!-- 7 Documento RFC -->
                    <div style="
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 2%;
            " v-show="state.document_rfc">
                        <box-icon name="check" color="#39811d"></box-icon>
                        <a style="
                text-decoration: none;
                display: flex;
                justify-content: center;
                align-items: center;
              " title="Documento RFC" target="_blank" :href="state.document_rfc?.link">
                            <box-icon name="file" color="#106cf6"></box-icon>
                            <small>Documento Constancia de Situación Fiscal</small>
                        </a>
                    </div>
                    <!-- 8 Documento Seguro -->
                    <div style="
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 2%;
            " v-show="state.document_seguro">
                        <box-icon name="check" color="#39811d"></box-icon>
                        <a style="
                text-decoration: none;
                display: flex;
                justify-content: center;
                align-items: center;
              " title="eguro facultativo/SGMM" target="_blank" :href="state.document_seguro?.link">
                            <box-icon name="file" color="#106cf6"></box-icon>
                            <small>Seguro facultativo/SGMM</small>
                        </a>
                    </div>
                    <!-- 9 Documento Personales -->
                    <div style="
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 2%;
            " v-show="state.document_personales">
                        <box-icon name="check" color="#39811d"></box-icon>
                        <a style="
                text-decoration: none;
                display: flex;
                justify-content: center;
                align-items: center;
              " title="Documento Personales" target="_blank" :href="state.document_personales?.link">
                            <box-icon name="file" color="#106cf6"></box-icon>
                            <small>Hoja de Datos Personales</small>
                        </a>
                    </div>
                    <!-- 10 Documento Accidentes -->
                    <div style="
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 2%;
            " v-show="state.document_accidentes">
                        <box-icon name="check" color="#39811d"></box-icon>
                        <a style="
                text-decoration: none;
                display: flex;
                justify-content: center;
                align-items: center;
              " title="Solicitud de poliza contra accidentes" target="_blank" :href="state.document_accidentes?.link">
                            <box-icon name="file" color="#106cf6"></box-icon>
                            <small>Solicitud de poliza contra accidentes</small>
                        </a>
                    </div>
                    <!-- 11 Historial Académico -->
                    <div style="
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 2%;
            " v-show="state.document_historialAcademico">
                        <box-icon name="check" color="#39811d"></box-icon>
                        <a style="
                text-decoration: none;
                display: flex;
                justify-content: center;
                align-items: center;
              " title="Historial Académico" target="_blank" :href="state.document_historialAcademico?.link">
                            <box-icon name="file" color="#106cf6"></box-icon>
                            <small>Historial Académico</small>
                        </a>
                    </div>
                    <!-- 12 Documento Horario Escolar -->
                    <div style="display: flex; justify-content: center;align-items: center;padding: 2%;" v-show="state.document_horario">
                        <box-icon name="check" color="#39811d"></box-icon>
                        <a style="text-decoration: none;display: flex;justify-content: center;align-items: center; " title="Horario Escolar" target="_blank" :href="state.document_horario?.link">
                            <box-icon name="file" color="#106cf6"></box-icon>
                            <small>Horario Escolar</small>
                        </a>
                    </div>
                    <!-- 13 Comprobante de inscripción -->
                    <div style="display: flex;justify-content: center;align-items: center; padding: 2%;" v-show="state.document_ComprobanteInscripcion">
                        <box-icon name="check" color="#39811d"></box-icon>
                        <a style="text-decoration: none;display: flex;justify-content: center;align-items: center;" title="Documento Accidentes" target="_blank" :href="state.document_ComprobanteInscripcion?.link">
                            <box-icon name="file" color="#106cf6"></box-icon>
                            <small>Comprobante de Inscripción</small>
                        </a>
                    </div>
                </div>
                <!--Upload inicial Egresado -->
                <div style="
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            padding: 2rem;
          " v-show="state.informationVacante.type === 'Egresado'">
                    <!-- CV -->
                    <div style="
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 2%;
            " v-show="state.informationUser.filecv">
                        <box-icon name="check" color="#39811d"></box-icon>
                        <a style="
                text-decoration: none;
                display: flex;
                justify-content: center;
                align-items: center;
              " title="CV" target="_blank" :href="state.informationUser.filecv?.link">
                            <box-icon name="file" color="#106cf6"></box-icon>
                            <small>CV</small>
                        </a>
                    </div>
                    <!-- estudios -->
                    <div style="
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 2%;
            " v-show="state.informationUser.filecomprobantes">
                        <box-icon name="check" color="#39811d"></box-icon>
                        <a style="
                text-decoration: none;
                display: flex;
                justify-content: center;
                align-items: center;
              " title="Documento Comprobante" target="_blank" :href="state.informationUser.filecomprobantes?.link">
                            <box-icon name="file" color="#106cf6"></box-icon>
                            <small>Comprobante de Estudios</small>
                        </a>
                    </div>
                    <!-- Domicilio -->
                    <div style="
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 2%;
            " v-show="state.document_comprobante">
                        <box-icon name="check" color="#39811d"></box-icon>
                        <a style="
                text-decoration: none;
                display: flex;
                justify-content: center;
                align-items: center;
              " title="Documento Comprobante" target="_blank" :href="state.document_comprobante?.link">
                            <box-icon name="file" color="#106cf6"></box-icon>
                            <small>Documento Comprobante de Domicilio</small>
                        </a>
                    </div>
                    <!-- Documento INE -->
                    <div style="
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 2%;
            " v-show="state.document_ine">
                        <box-icon name="check" color="#39811d"></box-icon>
                        <a style="
                text-decoration: none;
                display: flex;
                justify-content: center;
                align-items: center;
              " title="Documento INE" target="_blank" :href="state.document_ine?.link">
                            <box-icon name="file" color="#106cf6"></box-icon>
                            <small>Documento INE</small>
                        </a>
                    </div>
                    <!-- Documento Acta -->
                    <div style="
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 2%;
            " v-show="state.document_acta">
                        <box-icon name="check" color="#39811d"></box-icon>
                        <a style="
                text-decoration: none;
                display: flex;
                justify-content: center;
                align-items: center;
              " title="Documento Acta" target="_blank" :href="state.document_acta?.link">
                            <box-icon name="file" color="#106cf6"></box-icon>
                            <small>Documento Acta de Nacimiento</small>
                        </a>
                    </div>
                    <!-- Documento CURP -->
                    <div style="
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 2%;
            " v-show="state.document_curp">
                        <box-icon name="check" color="#39811d"></box-icon>
                        <a style="
                text-decoration: none;
                display: flex;
                justify-content: center;
                align-items: center;
              " title="Documento CURP" target="_blank" :href="state.document_curp?.link">
                            <box-icon name="file" color="#106cf6"></box-icon>
                            <small>Documento CURP</small>
                        </a>
                    </div>
                    <!-- Documento RFC -->
                    <div style="
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 2%;
            " v-show="state.document_rfc">
                        <box-icon name="check" color="#39811d"></box-icon>
                        <a style="
                text-decoration: none;
                display: flex;
                justify-content: center;
                align-items: center;
              " title="Documento RFC" target="_blank" :href="state.document_rfc?.link">
                            <box-icon name="file" color="#106cf6"></box-icon>
                            <small>Constancia de Situación Fiscal</small>
                        </a>
                    </div>
                    <!-- Documento Seguro -->
                    <div style="display: flex;justify-content: center;align-items: center;padding: 2%;" v-show="state.document_seguro">
                        <box-icon name="check" color="#39811d"></box-icon>
                        <a style="text-decoration: none;display: flex;justify-content: center;align-items: center;" title="Documento Seguro" target="_blank" :href="state.document_seguro?.link">
                            <box-icon name="file" color="#106cf6"></box-icon>
                            <small>Número de Seguridad Social</small>
                        </a>
                    </div>
                    <!-- Documento Personales -->
                    <div style="display: flex;justify-content: center;align-items: center;padding: 2%;" v-show="state.document_personales">
                        <box-icon name="check" color="#39811d"></box-icon>
                        <a style="text-decoration: none;display: flex;justify-content: center;align-items: center;" title="Documento Personales" target="_blank" :href="state.document_personales?.link">
                            <box-icon name="file" color="#106cf6"></box-icon>
                            <small>Hoja de Datos Personales</small>
                        </a>
                    </div>

                    <!-- Documento Infonavit -->
                    <div style="display: flex;justify-content: center;align-items: center;padding: 2%;" v-show="state.document_INFONAVIT?.link">
                        <box-icon name="check" color="#39811d"></box-icon>
                        <a style="text-decoration: none;display: flex;justify-content: center;align-items: center;" title="Documento infonavit" target="_blank" :href="state.document_INFONAVIT?.link">
                            <box-icon name="file" color="#106cf6"></box-icon>
                            <small>Documento Infonavit/Fonacot</small>
                        </a>
                    </div>
                </div>
            </div>
            <hr />
            <!-- Actualizar Documentos -->
            <div>
                <section>
                    <h5>Subir o Actualizar Documentos</h5>
                    <p>
                        En este apartado podrás subir tus documentos o actualizar los
                        documentos que ya has subido previamente
                    </p>
                </section>
                <section>
                    <!-- Becario -->
                    <div class="container-docs text-center mb-5" v-show="state.informationVacante.type === 'Becario'">
                        <!-- 1 Acta de Nacimiento -->
                        <form @submit.prevent="uploadFile('document_acta')"
                            class="card-docs card p-3 d-flex flex-column justify-content-center align-items-center mb-4">
                            <div class="input-doc mb-3 d-flex flex-column">
                                <label for="file_acta" class="form-label"><b>Acta de Nacimiento</b></label>
                                <label for="file_acta" class="form-label custom-file-upload">
                                    <input @change="uploadChange" class="form-control" type="file" id="file_acta"
                                        enctype="multipart/form-data" accept="application/pdf" required />
                                </label>
                            </div>
                            <div style="width: 50%" class="d-flex justify-content-center">
                                <button class="btn btn-success m-1" :disabled="response.loading" type="submit">
                                    Subir Archivo
                                    <div v-show="response.loading" class="spinner-border spinner-border-sm"
                                        role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </button>
                            </div>
                        </form>
                        <!-- 2 Comprobante de domicilio -->
                        <form @submit.prevent="uploadFile('document_comprobante')"
                            class="card-docs card p-3 d-flex flex-column justify-content-center align-items-center mb-4">
                            <div class="input-doc mb-3 d-flex flex-column">
                                <label for="file_comprobante" class="form-label"><b>Comprobante de domicilio</b></label>
                                <label for="file_comprobante" class="form-label custom-file-upload">
                                    <input @change="uploadChange" class="form-control" type="file" id="file_comprobante"
                                        enctype="multipart/form-data" accept="application/pdf" required />
                                </label>
                            </div>
                            <div style="width: 50%" class="d-flex justify-content-center">
                                <button class="btn btn-success m-1" :disabled="response.loading" type="submit">
                                    Subir Archivo
                                    <div v-show="response.loading" class="spinner-border spinner-border-sm"
                                        role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </button>
                            </div>
                        </form>
                        <!-- 3 Documento CURP -->
                        <form @submit.prevent="uploadFile('document_curp')"
                            class="card-docs card p-3 d-flex flex-column justify-content-center align-items-center mb-4">
                            <div class="input-doc mb-3 d-flex flex-column">
                                <label for="file_curp" class="form-label"><b>CURP</b></label>
                                <label for="file_curp" class="form-label custom-file-upload">
                                    <input @change="uploadChange" class="form-control" type="file" id="file_curp"
                                        enctype="multipart/form-data" accept="application/pdf" required />
                                </label>
                            </div>
                            <div style="width: 50%" class="d-flex justify-content-center">
                                <button class="btn btn-success m-1" :disabled="response.loading" type="submit">
                                    Subir Archivo
                                    <div v-show="response.loading" class="spinner-border spinner-border-sm"
                                        role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </button>
                            </div>
                        </form>
                        <!-- 4 Hoja de Datos Personales -->
                        <form @submit.prevent="uploadFile('document_personales')"
                            class="card-docs card p-3 d-flex flex-column justify-content-center align-items-center mb-4"
                            style="width: 40%">
                            <div class="input-doc mb-3 d-flex flex-column">
                                <label for="file_personales" class="form-label"><b>Hoja de Datos Personales</b></label>
                                <label for="file_personales" class="form-label custom-file-upload">
                                    <input @change="uploadChange" class="form-control" type="file" id="file_personales"
                                        enctype="multipart/form-data" accept="application/pdf" required />
                                </label>
                            </div>
                            <div style="width: 50%" class="d-flex justify-content-center">
                                <button class="btn btn-success m-1" :disabled="response.loading" type="submit">
                                    Subir Archivo
                                    <div v-show="response.loading" class="spinner-border spinner-border-sm"
                                        role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </button>
                            </div>
                        </form>
                        <!-- 5 INE -->
                        <form @submit.prevent="uploadFile('document_ine')"
                            class="card-docs card p-3 d-flex flex-column justify-content-center align-items-center mb-4">
                            <div class="input-doc mb-3 d-flex flex-column">
                                <label for="file_ine" class="form-label"><b>INE</b></label>
                                <label for="file_ine" class="form-label custom-file-upload">
                                    <input @change="uploadChange" class="form-control" type="file" id="file_ine"
                                        enctype="multipart/form-data" accept="application/pdf" required />
                                </label>
                            </div>
                            <div style="width: 50%" class="d-flex justify-content-center">
                                <button class="btn btn-success m-1" :disabled="response.loading" type="submit"
                                    role="status">
                                    Subir Archivo
                                    <div v-show="response.loading" class="spinner-border spinner-border-sm"
                                        role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </button>
                            </div>
                        </form>
                        <!-- 6 Documento Constancia de Situación Fiscal -->
                        <form @submit.prevent="uploadFile('document_rfc')"
                            class="card-docs card p-3 d-flex flex-column justify-content-center align-items-center mb-4">
                            <div class="input-doc mb-3 d-flex flex-column">
                                <label for="file_rfc" class="form-label"><b>Constancia de Situación Fiscal</b></label>
                                <label for="file_rfc" class="form-label custom-file-upload">
                                    <input @change="uploadChange" class="form-control" type="file" id="file_rfc"
                                        enctype="multipart/form-data" accept="application/pdf" required />
                                </label>
                            </div>
                            <div style="width: 50%" class="d-flex justify-content-center">
                                <button class="btn btn-success m-1" :disabled="response.loading" type="submit">
                                    Subir Archivo
                                    <div v-show="response.loading" class="spinner-border spinner-border-sm"
                                        role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </button>
                            </div>
                        </form>
                        <!-- 7 Accidentes -->
                        <form @submit.prevent="uploadFile('document_accidentes')"
                            class="card-docs card p-3 d-flex flex-column justify-content-center align-items-center mb-4">
                            <div class="input-doc mb-3 d-flex flex-column">
                                <label for="file_accidentes" class="form-label"><b>Solicitud de poliza contra
                                        accidentes</b></label>
                                <label for="file_accidentes" class="form-label custom-file-upload">
                                    <input @change="uploadChange" class="form-control" type="file" id="file_accidentes"
                                        enctype="multipart/form-data" accept="application/pdf" required />
                                </label>
                            </div>
                            <div style="width: 50%" class="d-flex justify-content-center">
                                <button class="btn btn-success m-1" :disabled="response.loading" type="submit">
                                    Subir Archivo
                                    <div v-show="response.loading" class="spinner-border spinner-border-sm"
                                        role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </button>
                            </div>
                        </form>
                        <!-- 8 Documento Seguro -->
                        <form @submit.prevent="uploadFile('document_seguro')"
                            class="card-docs card p-3 d-flex flex-column justify-content-center align-items-center mb-4">
                            <div class="input-doc mb-3 d-flex flex-column">
                                <label for="file_seguro" class="form-label"><b>Seguro Facultativo/SGMM</b></label>
                                <label for="file_seguro" class="form-label custom-file-upload">
                                    <input @change="uploadChange" class="form-control" type="file" id="file_seguro"
                                        enctype="multipart/form-data" accept="application/pdf" required />
                                </label>
                            </div>
                            <div style="width: 50%" class="d-flex justify-content-center">
                                <button class="btn btn-success m-1" :disabled="response.loading" type="submit">
                                    Subir Archivo
                                    <div v-show="response.loading" class="spinner-border spinner-border-sm"
                                        role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </button>
                            </div>
                        </form>
                        <!-- 9 Comprobante de Inscripcion -->
                        <form @submit.prevent="uploadFile('document_ComprobanteInscripcion')"
                            class="card-docs card p-3 d-flex flex-column justify-content-center align-items-center mb-4">
                            <div class="input-doc mb-3 d-flex flex-column">
                                <label for="file_ComprobanteInscripcion" class="form-label"><b>Comprobante de
                                        Inscripción</b></label>
                                <label for="file_ComprobanteInscripcion" class="form-label custom-file-upload">
                                    <input @change="uploadChange" class="form-control" type="file"
                                        id="file_ComprobanteInscripcion" enctype="multipart/form-data"
                                        accept="application/pdf" required />
                                </label>
                            </div>
                            <div style="width: 50%" class="d-flex justify-content-center">
                                <button class="btn btn-success m-1" :disabled="response.loading" type="submit">
                                    Subir Archivo
                                    <div v-show="response.loading" class="spinner-border spinner-border-sm"
                                        role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </button>
                            </div>
                        </form>
                        <!-- 10 horario escolar -->
                        <form @submit.prevent="uploadFile('document_horario')"
                            class="card-docs card p-3 d-flex flex-column justify-content-center align-items-center mb-4">
                            <div class="input-doc mb-3 d-flex flex-column">
                                <label for="file_horario" class="form-label"><b>Horario Escolar</b></label>
                                <label for="file_horario" class="form-label custom-file-upload">
                                    <input @change="uploadChange" class="form-control" type="file" id="file_horario"
                                        enctype="multipart/form-data" accept="application/pdf" required />
                                </label>
                            </div>
                            <div style="width: 50%" class="d-flex justify-content-center">
                                <button class="btn btn-success m-1" :disabled="response.loading" type="submit">
                                    Subir Archivo
                                    <div v-show="response.loading" class="spinner-border spinner-border-sm"
                                        role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </button>
                            </div>
                        </form>
                        <!-- 11 Historial Académico -->
                        <form @submit.prevent="uploadFile('document_historialAcademico')"
                            class="card-docs card p-3 d-flex flex-column justify-content-center align-items-center mb-4">
                            <div class="input-doc mb-3 d-flex flex-column">
                                <label for="file_historialAcademico" class="form-label"><b>Historial
                                        Académico</b></label>
                                <label for="file_historialAcademico" class="form-label custom-file-upload">
                                    <input @change="uploadChange" class="form-control" type="file"
                                        id="file_historialAcademico" enctype="multipart/form-data"
                                        accept="application/pdf" required />
                                </label>
                            </div>
                            <div style="width: 50%" class="d-flex justify-content-center">
                                <button class="btn btn-success m-1" :disabled="response.loading" type="submit">
                                    Subir Archivo
                                    <div v-show="response.loading" class="spinner-border spinner-border-sm"
                                        role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </button>
                            </div>
                        </form>
                        <!-- 12 Comprobante de estudios -->
                        <form @submit.prevent="uploadFile('filecomprobantes')"
                            class="card-docs card p-3 d-flex flex-column justify-content-center align-items-center mb-4">
                            <div class="input-doc mb-3 d-flex flex-column">
                                <label for="file_filecomprobantes" class="form-label"><b>Comprobante de Estudios</b></label>
                                <label for="file_filecomprobantes" class="form-label custom-file-upload">
                                    <input @change="uploadChange" class="form-control" type="file"
                                        id="file_filecomprobantes" enctype="multipart/form-data"
                                        accept="application/pdf" required />
                                </label>
                            </div>
                            <div style="width: 50%" class="d-flex justify-content-center">
                                <button class="btn btn-success m-1" :disabled="response.loading" type="submit">
                                    Subir Archivo
                                    <div v-show="response.loading" class="spinner-border spinner-border-sm"
                                        role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </button>
                            </div>
                        </form>
                    </div>
                    <!-- Egresado -->
                    <div class="container-docs text-center mb-5" v-show="state.informationVacante.type === 'Egresado'">
                        <!-- 1 Acta de Nacimiento -->
                        <form @submit.prevent="uploadFile('document_acta')"
                            class="card-docs card p-3 d-flex flex-column justify-content-center align-items-center mb-4">
                            <div class="input-doc mb-3 d-flex flex-column">
                                <label for="file_acta" class="form-label"><b>Acta de Nacimiento</b></label>
                                <label for="file_acta" class="form-label custom-file-upload">
                                    <input @change="uploadChange" class="form-control" type="file" id="file_acta"
                                        enctype="multipart/form-data" accept="application/pdf" required />
                                </label>
                            </div>
                            <div style="width: 50%" class="d-flex justify-content-center">
                                <button class="btn btn-success m-1" :disabled="response.loading" type="submit">
                                    Subir Archivo
                                    <div v-show="response.loading" class="spinner-border spinner-border-sm"
                                        role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </button>
                            </div>
                        </form>
                        <!-- 2 Comprobante de domicilio -->
                        <form @submit.prevent="uploadFile('document_comprobante')"
                            class="card-docs card p-3 d-flex flex-column justify-content-center align-items-center mb-4">
                            <div class="input-doc mb-3 d-flex flex-column">
                                <label for="file_comprobante" class="form-label"><b>Comprobante de domicilio</b></label>
                                <label for="file_comprobante" class="form-label custom-file-upload">
                                    <input @change="uploadChange" class="form-control" type="file" id="file_comprobante"
                                        enctype="multipart/form-data" accept="application/pdf" required />
                                </label>
                            </div>
                            <div style="width: 50%" class="d-flex justify-content-center">
                                <button class="btn btn-success m-1" :disabled="response.loading" type="submit">
                                    Subir Archivo
                                    <div v-show="response.loading" class="spinner-border spinner-border-sm"
                                        role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </button>
                            </div>
                        </form>
                        <!-- 3 Documento Seguro -->
                        <form @submit.prevent="uploadFile('document_seguro')"
                            class="card-docs card p-3 d-flex flex-column justify-content-center align-items-center mb-4">
                            <div class="input-doc mb-3 d-flex flex-column">
                                <label for="file_seguro" class="form-label"><b>Número de Seguridad Social</b></label>
                                <label for="file_seguro" class="form-label custom-file-upload">
                                    <input @change="uploadChange" class="form-control" type="file" id="file_seguro"
                                        enctype="multipart/form-data" accept="application/pdf" required />
                                </label>
                            </div>
                            <div style="width: 50%" class="d-flex justify-content-center">
                                <button class="btn btn-success m-1" :disabled="response.loading" type="submit">
                                    Subir Archivo
                                    <div v-show="response.loading" class="spinner-border spinner-border-sm"
                                        role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </button>
                            </div>
                        </form>
                        <!-- 4 Documento CURP -->
                        <form @submit.prevent="uploadFile('document_curp')"
                            class="card-docs card p-3 d-flex flex-column justify-content-center align-items-center mb-4">
                            <div class="input-doc mb-3 d-flex flex-column">
                                <label for="file_curp" class="form-label"><b>CURP</b></label>
                                <label for="file_curp" class="form-label custom-file-upload">
                                    {{ nameDo }}
                                    <input @change="uploadChange" class="form-control" type="file" id="file_curp"
                                        enctype="multipart/form-data" accept="application/pdf" required />
                                </label>
                            </div>
                            <div style="width: 50%" class="d-flex justify-content-center">
                                <button class="btn btn-success m-1" :disabled="response.loading" type="submit">
                                    Subir Archivo
                                    <div v-show="response.loading" class="spinner-border spinner-border-sm"
                                        role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </button>
                            </div>
                        </form>
                        <!-- 5 Documento Constancia de Situación Fiscal -->
                        <form @submit.prevent="uploadFile('document_rfc')"
                            class="card-docs card p-3 d-flex flex-column justify-content-center align-items-center mb-4">
                            <div class="input-doc mb-3 d-flex flex-column">
                                <label for="file_rfc" class="form-label"><b>Constancia de Situación Fiscal</b></label>
                                <label for="file_rfc" class="form-label custom-file-upload">
                                    <input @change="uploadChange" class="form-control" type="file" id="file_rfc"
                                        enctype="multipart/form-data" accept="application/pdf" required />
                                </label>
                            </div>
                            <div style="width: 50%" class="d-flex justify-content-center">
                                <button class="btn btn-success m-1" :disabled="response.loading" type="submit">
                                    Subir Archivo
                                    <div v-show="response.loading" class="spinner-border spinner-border-sm"
                                        role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </button>
                            </div>
                        </form>
                        <!-- 6 Hoja de Datos Personales -->
                        <form @submit.prevent="uploadFile('document_personales')"
                            class="card-docs card p-3 d-flex flex-column justify-content-center align-items-center mb-4">
                            <div class="input-doc mb-3 d-flex flex-column">
                                <label for="file_personales" class="form-label"><b>Hoja de Datos Personales</b></label>
                                <label for="file_personales" class="form-label custom-file-upload">
                                    <input @change="uploadChange" class="form-control" type="file" id="file_personales"
                                        enctype="multipart/form-data" accept="application/pdf" required />
                                </label>
                            </div>
                            <div style="width: 50%" class="d-flex justify-content-center">
                                <button class="btn btn-success m-1" :disabled="response.loading" type="submit">
                                    Subir Archivo
                                    <div v-show="response.loading" class="spinner-border spinner-border-sm"
                                        role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </button>
                            </div>
                        </form>
                        <!-- 7 INFONAVIT/FONACOT -->
                        <form @submit.prevent="uploadFile('document_INFONAVIT')"
                            class="card-docs card p-3 d-flex flex-column justify-content-center align-items-center mb-4">
                            <div class="input-doc mb-3 d-flex flex-column">
                                <label for="file_infonavit" class="form-label"><b>Hoja de INFONAVIT/FONACOT</b></label>
                                <label for="file_infonavit" class="form-label custom-file-upload">
                                    <input @change="uploadChange" class="form-control" type="file" id="file_infonavit"
                                        enctype="multipart/form-data" accept="application/pdf" required />
                                </label>
                            </div>
                            <div style="width: 50%" class="d-flex justify-content-center">
                                <button class="btn btn-success m-1" :disabled="response.loading" type="submit">
                                    Subir Archivo
                                    <div v-show="response.loading" class="spinner-border spinner-border-sm"
                                        role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </button>
                            </div>
                        </form>
                        <!-- 8 INE -->
                        <form @submit.prevent="uploadFile('document_ine')"
                            class="card-docs card p-3 d-flex flex-column justify-content-center align-items-center mb-4">
                            <div class="input-doc mb-3 d-flex flex-column">
                                <label for="file_ine" class="form-label"><b>INE</b></label>
                                <label for="file_ine" class="form-label custom-file-upload">
                                    <input @change="uploadChange" class="form-control" type="file" id="file_ine"
                                        enctype="multipart/form-data" accept="application/pdf" required />
                                </label>
                            </div>
                            <div style="width: 50%" class="d-flex justify-content-center">
                                <button class="btn btn-success m-1" :disabled="response.loading" type="submit">
                                    Subir Archivo
                                    <div v-show="response.loading" class="spinner-border spinner-border-sm"
                                        role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </button>
                            </div>
                        </form>
                        <!-- 9 Comprobante de estudios -->
                        <form @submit.prevent="uploadFile('filecomprobantes')"
                            class="card-docs card p-3 d-flex flex-column justify-content-center align-items-center mb-4">
                            <div class="input-doc mb-3 d-flex flex-column">
                                <label for="file_filecomprobantes" class="form-label"><b>Comprobante de Estudios</b></label>
                                <label for="file_filecomprobantes" class="form-label custom-file-upload">
                                    <input @change="uploadChange" class="form-control" type="file"
                                        id="file_filecomprobantes" enctype="multipart/form-data"
                                        accept="application/pdf" required />
                                </label>
                            </div>
                            <div style="width: 50%" class="d-flex justify-content-center">
                                <button class="btn btn-success m-1" :disabled="response.loading" type="submit">
                                    Subir Archivo
                                    <div v-show="response.loading" class="spinner-border spinner-border-sm"
                                        role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </button>
                            </div>
                        </form>
                    </div>
                </section>
            </div>
        </section>
    </main>
</template>

<script>
import { UserService } from "@/services";

import moment from "moment-timezone";
moment.tz.setDefault("America/Mexico_City");

export default {
    data: () => ({
        postulaciones: [],
        moment: moment,
        file_name: null,
        file: null,
        //becario
        document: null,
        id_vacante: "",
        response: {
            loading: false,
        },
        dataUser: {},
        enableInput: true,
    }),
    mounted() {
        const vacante = this.$route.params.id;
        this.id_vacante = this.$route.params.id;
        this.getDocuments(vacante);
    },
    components: {
        //InputPartials,
    },
    methods: {
        async getDocuments(params) {
            let resp = await UserService.getDocuments({ _id_vacante: params });
            console.log(resp);
            this.postulaciones = resp.data.data;
        },
        isObjectEmpty(obj) {
            return !obj || Object.keys(obj).length === 0;
        },
        clearInputFile(name) {
            let file = document.getElementById(name);
            let emptyFile = document.createElement("input");
            emptyFile.type = "file";
        },
        uploadChange: function (e) {
            let pesoMaximo = 5000000;
            const files = e.target.files;
            let sizeFile = files[0].size;
            if (files[0] === undefined) {
                return null;
            }
            if (sizeFile > pesoMaximo) {
                this.$swal({
                    position: "top-center",
                    icon: "error",
                    text: "El tamaño máximo por archivo es de 5mb",
                    showConfirmButton: false,
                    timer: 3500,
                });
                return null;
            }
            const imageName = files[0].name;
            if (imageName.lastIndexOf(".") <= 0) {
                return null;
            }
            if (!/\.(pdf)$/i.test(imageName)) {
                this.$swal({
                    position: "top-end",
                    toast: true,
                    icon: "error",
                    text: "El archivo no es .pdf",
                    showConfirmButton: false,
                    timer: 3000,
                });
                return null;
            }
            const fileRead = new FileReader();
            fileRead.readAsDataURL(files[0]);
            fileRead.addEventListener("load", () => {
                const base64code = fileRead.result;
                this.document = base64code;
            });
        },
        async uploadFile(name) {
            this.response.loading = true;
            let updateDocumentosEgresado = {
                _id_vacante: this.id_vacante,
                name: name,
                document: this.document,
            };
            await UserService.updateDocumentsUsers(updateDocumentosEgresado)
                .then((response) => {
                    this.$swal({
                        position: "top-center",
                        icon: "success",
                        text: response.data.message,
                        showConfirmButton: false,
                        timer: 2500,
                    });
                    this.clearInputFile(name);
                    this.getDocuments(this.id_vacante);

                    this.response.loading = false;
                })
                .catch((e) => {
                    this.response.loading = false;
                    this.$swal({
                        position: "top-center",
                        icon: "error",
                        text: "Error" + e,
                        showConfirmButton: false,
                        timer: 2500,
                    });
                });
        },
        editInf() {
            this.enableInput == true
                ? (this.enableInput = false)
                : (this.enableInput = true);
        },
    },
};
</script>

<style land="scss">
@import "../../styles/registerClient.scss";
</style>
